/* Top Nav Styles */
.app-main-header {
    //z-index: 1302 !important;
    background-color: #FFF !important;
    color: #898989 !important;

    & .search-bar input {
        @include transition($transition-base);
        background-color: lighten($app-primary, 5%);
        color: $white;
        & + .search-icon {
            top: 0;
            & i {
                color: rgba($white, 0.8);
            }
        }

        &:focus {
            background-color: $white;
            color: $body-color;
            + .search-icon {
                & i {
                    color: rgba($body-color, 0.8);
                }
            }
        }
    }
}

.app-toolbar {
    .app-logo img {
        height: 40px;
        @media screen and (max-width: 991px) {
            height: 35px;
        }
    }
    > .top-nav {
        > ul {
            margin-bottom: 0;
            > a {
                img {
                    display: block;
                    &.active {
                        display: none;
                    }
                }
                &.active {
                    color: $main;
                    img {
                        display: none;
                        &.active {
                            display: block;
                        }
                    }
                }
            }
        }
        .lang {
            padding-left: 15px;
        }
        .list-inline-item {
            img {
                width: 30px;
                height: auto;
            }
            &.text-nav {
                @media screen and (max-width: 991px) {
                    padding: 0 18px;
                    &:hover {
                        padding: 0 18px;
                    }
                }
                @media screen and (max-width: 480px) {
                    padding: 0 12px;
                    &:hover {
                        padding: 0 12px;
                    }
                }
            }
            &.img-nav,
            &.lang-nav {
                padding: 5px;
                cursor: pointer;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.08);
                    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    border-radius: 5%;
                }
            }
        }
        .user-nav {
            cursor: pointer;
            width: 200px;
            display: inline-block;
            border-left: 1px solid $main;
            padding-left: 30px;
            //>a {
            >.item {
                width: 150px;
                margin: 0 auto;
                display: block;
                >.avatar {
                    float: left;
                    display: block;
                }
                >.text {
                    color: $main;
                    display: block;
                    line-height: 30px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: center;
                    padding-left: 5px;
                }
            }
            //}
        }
    }
}
