/* Banner Style */
$banner-height: 520px; //500px

.banner {
    @media (min-width: 992px) {
        height: 80vh;
    }
    &.hk {
        font-family: 'cwTeXYen', sans-serif;
    }
    height: $banner-height;
    background: url('../img/banner.jpg') no-repeat center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;

    text-align: center;
    //background: linear-gradient(135deg, #98DAD1 40%, #11A9A7 100%);
    //background-color: #d7edff !important;
    > div {
        .content {
            > div {
                &.tagline {
                    //padding-top: 100px;
                    @media (min-width: 992px) {
                        //padding-top: 170px;
                    }
                    img {
                        @media (min-width: 992px) {
                            width: 70%;
                        }
                        width: 95%;
                        height: auto;
                    }
                }
                &.google-play {
                    //padding-top: 80px;
                    img {
                        width: 300px;
                        @media (min-width: 992px) {
                            width: 200px;
                        }
                        height: auto;
                    }
                }
            }
        }
    }
}
