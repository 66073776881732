/* Loading Style */
.loading {
    display: none;
    &.show {
        display: block;
        position: fixed;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
        z-index: 9999;
        > div {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
        }
    }
}