/*Footer Styles*/

.footer {
    background-color: $gray-850;
    padding: 2rem 1rem;
    text-align: center;
    color: $white;
    >img.logo {
        width: 150px;
        height: auto;
    }
    >p {
        padding: 1rem 0;
    }
    >.social-media {
        display: block;
        @media (min-width: 992px) {
            display: inline-flex;
        }
        justify-content: center;
        align-items: center;
        > a {
            div {
                &.circle {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    display: inline-block;
                    background-color: $gray-400;
                    border: 2px solid $gray-400;
                    border-radius: 60px;
                    margin: 0 25px;
                }
                >img {
                    width: 40px;
                    height: auto;
                    position:absolute;
                    top:0;
                    bottom:0;
                    left: 0;
                    right: 0;
                    margin:auto;
                }
            }
        }
        .google-play {
            a {
                > img {
                    width: 200px;
                    height: auto;
                }
            }
        }
    }
}
.tnc {
    padding: 2rem 0;
    background-color: $gray-900;
    color: $white;
    > div {
        a {
            color: $white;
            padding-right: 20px;
        }
        @media screen and (max-width: 767px) {
            > div:nth-child(2) {
                padding-top: 20px;
            }
        }
    }

}
