/*Main Content Styles*/
.app-main-content {
      //flex: 1;
      width: 100%;
}
.app-wrapper {
      padding: 12px;
      width: 100%;

      @media screen and (max-width: 575px) {
        padding: 0px;
      }
}

.container {
    padding-left: 0px;
    padding-right: 0px;
}

.main-view {
    &.scrollbar {
        height: calc(100vh - #{$app-bar-height}) !important;
    }
}

.flex-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important;
}

#section-b {
    background: url('../img/section-b.jpg') no-repeat center 30%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;

    > div > div {
        padding: 60px 0;
        > .title {
            padding: 20px 0;
            color: white;
            font-weight: bold;
            font-size: 1.8rem;
            > span {
                &:nth-child(1) {
                    display: block;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
                &:nth-child(2) {
                    display: none;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
                &:nth-child(3) {
                    display: block;
                    @media (min-width: 992px) {
                        display: inline-block;
                    }
                }
            }
        }
        > .content {
            padding: 10px 8px;
            display: inline-block;
            color: white;
            background: rgba(250, 168, 18, 0.7);
        }
    }
}

#section-c {
    text-align: center;
    background-color: white;
    > div > div {
        padding: 60px;
        > .content {
            font-weight: bold;
            font-size: 1.6rem;
            > span {
                &.highlight {
                    color: #FAA812;
                    font-size: 2.2rem;
                }
            }
            > br {
                display: none;
                @media (min-width: 992px) {
                    display: block;
                }
            }
        }
    }
}

#section-d {
    background: url('../img/section-d.jpg') no-repeat center 0%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center;

    > div > div {
        padding: 40px 0 25px 0;
        .title {
            color: white;
            font-weight: bold;
            font-size: 2.4rem;
            padding-bottom: 20px;

            > p:nth-child(1) {
                margin-bottom: 0px;
                @media (min-width: 992px) {
                    margin-bottom: 5px;
                }
            }

            > div {
                 display: -webkit-box;
                 display: -ms-flexbox;
                 display: flex;
                 height: 100%;
                 -webkit-box-align: center;
                    -ms-flex-align: center;
                       align-items: center;
                 -webkit-box-pack: center;
                    -ms-flex-pack: center;
                  justify-content: center;
            }

            .button {
                background-color: #8CDBF4;
                display: inline-block;
                padding: 8px 12px;
                font-size: 1.5rem;
                font-weight: normal;
                color: #1A7084;
                border-radius: 10px;
            }
        }
        .content {
            display: inline-block;
            padding: 6px 10px;
            color: white;
            border: 1px solid white;
        }
    }
}

#section-e {
    padding: 30px;
    > .container {
        max-width: 800px;
    }
    > div {
        .title {
            font-size: 1.2rem;
            padding-bottom: 20px;
        }
        .content {
            padding-bottom: 20px;
            img {
                width: 25%;
                height: auto;
                @media (min-width: 992px) {
                    width: 50%;
                }
            }
            p {
                padding-top: 15px;
                font-size: 1.2rem;
                @media (min-width: 992px) {
                    font-size: 1rem;
                }
            }
        }
        .bottom {
            > p {
                &:nth-child(1) {
                    margin-bottom: 10px;
                }
                &:nth-child(2) {
                    font-size: 0.5rem;
                }
            }
        }
    }
}
